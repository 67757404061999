<template>
  <div>
    <LibPageContainer :color-mode="$colorMode">
      <LibLocaleVersionOverlayContainer />
      <DocumentCenterStandaloneModal />
      <SoftwareCenterStandaloneModal />
      <slot />
    </LibPageContainer>
    <AudienceWidget />
  </div>
</template>

<script setup lang="ts">
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';
import DocumentCenterStandaloneModal from '~/components/downloads/documents/DocumentCenterStandaloneModal.vue';
import SoftwareCenterStandaloneModal from '~/components/downloads/software/SoftwareCenterStandaloneModal.vue';
import AudienceWidget from '~/components/lib/AudienceWidget.vue';

const locale = useLocale();
const { $colorMode } = useNuxtApp();
const geoInformationStore = useGeoinformationStore();
const logger = useLogger();

useGlobalMetadata();

onMounted(() => {
  // load initial geo information only on client side once inside layout
  geoInformationStore.init().catch(logger.error);
});

const isAsian = computed(() => {
  const asianRegions: Lowercase<LOCALE_CODE>[] = [
    'zh-cn',
    'zh-hant-tw',
    'ja-jp',
    'ko-kr',
    'ru-ru'
  ];

  return asianRegions.includes(locale.value as Lowercase<LOCALE_CODE>);
});
const styles = useCssModule();
// Using state values is possible inside head but not on component used in
// layout (layout is rendered before actual slot components which
// can manipulate state. Even though state changes, layout is not updated.
useHead({
  bodyAttrs: {
    class: () => ({
      'is-region-asian': isAsian.value,
      'is-region-western': !isAsian.value,
      'page-wrapper--dark': $colorMode.value === 'dark',
      'page-wrapper--light': $colorMode.value === 'light',
      'page-wrapper--dark-purple': $colorMode.value === 'dark-purple',
      [styles.dark]: $colorMode.value === 'dark',
      [styles.light]: $colorMode.value === 'light',
      [styles['dark-purple']]: $colorMode.value === 'dark-purple'
    })
  }
});
</script>

<style module>
.light {
  --app-canvas-effect-opacity: 1;
  --app-canvas-color-canvas: #002a95;
}

.dark {
  --app-canvas-effect-opacity: 0;
  --app-canvas-color-canvas: #002a95;
}

.dark-purple {
  --app-canvas-effect-opacity: 0;
  --app-canvas-color-canvas: #38068a;
}
</style>
